@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --tablet-portret: 768px;
  --tablet-portret_unit: 768;
  --desktop-unit: 1312;
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #fafafa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lexend-100 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.lexend-200 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.lexend-300 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.lexend-400 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.lexend-500 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.lexend-600 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.lexend-700 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.lexend-800 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.lexend-900 {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}


.dialog-open {
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .fnt-83-166 {
    font-size: clamp(83.3px, calc(83.3px + (166.59 - 83.3) * ((100vw - 768px) / (1440 - 768))), 166.59px) !important;
    line-height: 1.4;
  }
}


/* Subscribe form */
.email-form {
  display: flex;
  height: 46px;
  position: relative;
  overflow: hidden;
}

.email-form .button {
  height: 100%;
  display: inline-block;
  padding: 9px 15px;
  background-color: #e74e43;
  color: white;
  border: none;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 24px;
  position: absolute;
  right: 0px;
}

.email-form .input {
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  vertical-align: middle;
  background: #373737 d0;
  color: white;
  border-radius: 24px;
  border: none;
  outline: none;
}

.email-form .input::placeholder {
  color: white;
}

.email-form .error {
  color: #e74e43;
}

.email-form.start {
  height: auto;
}
